<div class="header container" [class.header-shadow]="router.url !== '/'">
    <a *ngIf="router.url === '/termin'" routerLink="/"><img src="./assets/img/logo.png"></a>
    <img *ngIf="router.url !== '/termin'" src="./assets/img/logo.png">
    <a class="certificate-link" href="https://m.certipedia.com/certificates/01%20600%202000730?locale=de"
        target="blank">
        <img *ngIf="router.url === '/termin'" src="./assets/img/TR-Testmark_9000012503_DE_CMYK_without-QR-Code.png"
            class="certificate">
    </a>



    <div class="hide-mobile nav-cont" *ngIf="router.url !== '/termin' && router.url !== '/bewerbung-eingegangen'">
        <a href=" #casestudies">FALLSTUDIEN</a>
        <a href="#aboutus">ÜBER UNS</a>
        <a routerLink="/termin" class="apply-btn">JETZT BEWERBEN</a>
    </div>
</div>
