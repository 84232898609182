<div class="landing-fold mt-l container">
    <div>
        <h1>Online-Weiterbildung zum <span class="text-pink">
                <b>Softwareentwickler</b>
            </span>
        </h1>
        <p class="font-size-large pr-xl landing-p">
            <b>Als Quereinsteiger</b> in <b>3</b> bis <b>9</b> Monaten zu einem gefragten Web-Programmierer werden und
            anschließend den ersten Job in der IT-Branche finden. Frontend. Backend. Fullstack.
        </p>

        <div class="tp-tuev-container">
            <!-- TrustBox widget - Mini -->
            <div class="trustpilot-widget tpw-top" data-locale="de-DE" data-template-id="53aa8807dec7e10d38f59f32"
                data-businessunit-id="5e9825574f392a0001029067" data-style-height="120px" data-style-width="100%"
                data-theme="light" data-text-color="#050505">
                <a href="https://de.trustpilot.com/review/developerakademie.com" target="_blank"
                    rel="noopener">Trustpilot</a>
            </div>
            <!-- End TrustBox widget -->

            <a href="https://m.certipedia.com/certificates/01%20600%202000730?locale=de" target="blank">
                <img class="tuev" src="./assets/img/TR-Testmark_9000012503_DE_CMYK_without-QR-Code.png" alt="">
            </a>
        </div>

        <div class="mt-l">
            <a routerLink="/termin" class="apply-btn">
                <i class="fa fa-arrow-right fa_prepended"></i> Kostenlose Beratung
            </a>
        </div>
    </div>
    <div class="hide-mobile">
        <img class="girl" src="./assets/img/girl.jpg">
    </div>
    <div class="flex-center mouse-icon hide-mobile"><img src="./assets/img/icons8-mouse-64.jpg"></div>
</div>



<!-- Quote -->
<div class="quote container">
    <div class="flex-center">
        <img src="./assets/img/quote.png">
    </div>
    <p class="font-size-large text-center letter-spacing-1">"Die Digitalisierung ist jetzt auch in Deutschland voll
        angekommen. Auf dem Jobmarkt suchen überall tolle Unternehmen verzweifelt nach gut ausgebildeten
        Programmierern." </p>
    <div>
        <span class="text-pink">MANUEL THALER</span>
    </div>
</div>

<div class="video-section container table">
    <div class="table-cell pr-m">
        <div class="card flex-center">
            <!-- video-container -->
            <iframe src="//fast.wistia.net/embed/iframe/it01z2zp9u" class="big-screen-only" allowtransparency="true"
                frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen mozallowfullscreen
                webkitallowfullscreen oallowfullscreen msallowfullscreen width="620" height="349"></iframe>
            <iframe src="//fast.wistia.net/embed/iframe/it01z2zp9u" class="medium-screen-only" allowtransparency="true"
                frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen mozallowfullscreen
                webkitallowfullscreen oallowfullscreen msallowfullscreen width="420" height="240"></iframe>
            <iframe src="//fast.wistia.net/embed/iframe/it01z2zp9u" class="small-screen-only" allowtransparency="true"
                frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen mozallowfullscreen
                webkitallowfullscreen oallowfullscreen msallowfullscreen width="420" height="240"></iframe>
            <iframe src="//fast.wistia.net/embed/iframe/it01z2zp9u" class="very-small-screen-only"
                allowtransparency="true" frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen
                mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="320"
                height="180"></iframe>
        </div>
    </div>
    <div class="table-cell v-align-bottom video-description">
        <div class="line-black hide-mobile"></div>
        <h2 class="text-pink font-size-medium"><b>Als Quereinsteiger in die IT-Branche</b></h2>
        <p class="text-left font-size-large">Durch <b>1:1 Online-Training</b> mit erfahrenen Coaches
            <b>Web-Programmierer</b> werden, ohne dafür Jahre an Zeit zu verlieren.
        </p>

        <div class="mt-s d-flex">
            <a routerLink="/termin" routerLinkActive="router-link-active" class="apply-btn">
                <i class="fa fa-arrow-right fa_prepended"></i> Kostenlose Beratung
            </a>
        </div>
    </div>
</div>



<div class="case-study-wrapper">
    <div class="customer-section bg-dark container" id="casestudies">
        <h2 class="font-size-larger"><b>KUNDEN-FALLSTUDIEN AUS DER PRAXIS</b></h2>

        <div class="customer-container">
            <div class="customer-picture">
                <div class="flex-center"> <img src="./assets/img/atila.png"> </div>
                <div class="flex-center mt-m"> <b>Atila (Industrial Engineer)</b></div>
            </div>
            <div class="customer-seperator hide-mobile">
                <div class="vertical-seperator"></div>
            </div>
            <div class="customer-description">
                <p class="customer-info-headline"><b class="text-pink">Ausgangslage</b></p>
                <p class="customer-info-text"><i>Atila kommt ursprünglich aus Brasilien und hat dort Industrial
                        Engineering studiert. In Deutschland arbeitete er zuerst in der Produktion in einer Firma. Er
                        hat lange versucht, seinen Traum Programmierer zu werden mit kleinen Kursen zu schaffen, aber
                        gemerkt, dass dies nicht so leicht ist.
                    </i> </p>
                <p class="customer-info-headline"><b class="text-pink">Ziel</b></p>
                <p class="customer-info-text"><i>Atilas Ziel war den ersten Job in der IT als Programmierer zu ergattern
                        und in der Lage zu sein, eigene Projekte selber umzusetzen.
                    </i></p>

                <p class="customer-info-headline"><b class="text-pink">Ergebnis</b></p>
                <p class="customer-info-text"><i>Atila hatte u.a. auch noch andere konkrete Optionen im gesamten
                        Bundesgebiet. Zum Beispiel in Bremen oder in Frankfurt oder auch Remote. Atila hat sich dann
                        dazu entschieden bei einer Softwareagentur in München, die Partner der Developer Akademie ist,
                        als Junior Entwickler zu starten.</i> </p>
            </div>
        </div>

        <div class="mt-l customer-container">

            <div class="customer-description">
                <p class="customer-info-headline"><b class="text-pink">Ausgangslage</b></p>
                <p class="customer-info-text"><i>David hat Politikwissenschaften im Master studiert und startete bei der
                        Developer Akademie mit 29
                        Jahren.
                        David hat zuvor versucht, sich über Online-Videos Programmieren beizubringen, sich dann aber
                        dazu entschieden professionell Programmieren zu lernen.
                    </i> </p>
                <p class="customer-info-headline"><b class="text-pink">Ziel</b></p>
                <p class="customer-info-text"><i>David plante als Quereinsteiger in den Tech-Bereich zu gehen und dort
                        nach ein paar eigenen
                        Projekten
                        als Web-Entwickler eine Anstellung zu finden.</i></p>

                <p class="customer-info-headline"><b class="text-pink">Ergebnis</b></p>
                <p class="customer-info-text"><i>David hat mehrere Job-Angebote als Junior Entwickler (Frontend)
                        bekommen, u.a. von einer Webagentur aus Hannover und einer großen Versicherung aus dem
                        Ruhrgebiet. Da sich gleichzeitig die Möglichkeit für einen Job in der Politik in Berlin ergab,
                        arbeitet David nun in der Politik und nutzt dort sein gewonnenes IT-Verständnis, um auf einen
                        weiteren Ausbau der Digitalisierung in den Bereichen der Sozial- und Arbeitspolitik
                        hinzuwirken.</i> </p>
            </div>

            <div class="customer-seperator hide-mobile">
                <div class="vertical-seperator"></div>
            </div>

            <div class="customer-picture">
                <div class="flex-center"> <img src="./assets/img/customer1.jpg"> </div>
                <div class="flex-center mt-m"> <b>David (M.A. Politikwissenschaften)</b></div>
            </div>

        </div>

        <div class="mt-l customer-container">

            <div class="customer-picture">
                <div class="flex-center"> <img src="./assets/img/carina.png"> </div>
                <div class="flex-center mt-m"> <b>Carina (M.Sc. Wirtschaftsingenieurwesen)</b></div>
            </div>

            <div class="customer-seperator hide-mobile">
                <div class="vertical-seperator"></div>
            </div>

            <div class="customer-description">
                <p class="customer-info-headline"><b class="text-pink">Ausgangslage</b></p>
                <p class="customer-info-text"><i>Carina ist studierte Wirtschaftsingenieurin aus Heidelberg und
                        arbeitete für einen großen Industriekonzern. Sie hat gemerkt, dass Software auch in der
                        Industrie immer wichtiger wird und wollte nicht nur neue Produkte vermarkten, sondern diese auch
                        selbst bauen können.
                    </i> </p>
                <p class="customer-info-headline"><b class="text-pink">Ziel</b></p>
                <p class="customer-info-text"><i>Carina hatte keinerlei Vorkenntnisse. Im Studium hatte sie wenige Zeilen Code mit
                        Python geschrieben und wollte den Quereinstieg in die IT schaffen.
                    </i></p>

                <p class="customer-info-headline"><b class="text-pink">Ergebnis</b></p>
                <p class="customer-info-text"><i>Carina hat es geschafft innerhalb von zwei Wochen nach Abschluss des
                        Programms ihren ersten Job als Softwareentwicklerin zu bekommen. Sie entwickelt nun Frontend &
                        Backend für Webanwendungen im IoT-Bereich für ein Münchner Unternehmen, das dank der tollen
                        Leistungen von Carina nun Partnerunternehmen der Developer Akademie ist. (Foto zeigt nicht
                        Carina)
                    </i> </p>
            </div>
        </div>

        <div class="mt-l customer-container">



            <div class="customer-description">
                <p class="customer-info-headline"><b class="text-pink">Ausgangslage</b></p>
                <p class="customer-info-text"><i>Eric hat Wirtschaftsinformatik in Wien studiert. Dort hat er vor allem
                        die theoretischen Konzepte der Softwareentwicklung kennengelernt und erste Erfahrung in der IT
                        gesammelt. Er arbeitete anschließend als Softwaretester.
                    </i>
                </p>
                <p class="customer-info-headline"><b class="text-pink">Ziel</b></p>
                <p class="customer-info-text"><i>Eric hat sich dazu entschieden sich beruflich weiterentwickeln und
                        den nächsten Karriereschritt in der IT zu machen. Er wollte nicht nur Software testen, sondern
                        auch neue Software entwickeln.
                    </i>
                </p>

                <p class="customer-info-headline"><b class="text-pink">Ergebnis</b></p>
                <p class="customer-info-text"><i>In einer Softwareagentur in Wien ist Eric Teil eines Teams, das für
                        große Kunden spannende Software entwickelt. U.a. arbeitet er an Projekten, die helfen
                        Infrastruktur mit Software abzubilden und zu steuern.
                    </i>
                </p>
            </div>

            <div class="customer-seperator hide-mobile">
                <div class="vertical-seperator"></div>
            </div>

            <div class="customer-picture">
                <div class="flex-center"> <img src="./assets/img/eric.png"> </div>
                <div class="flex-center mt-m"> <b>Eric (Wirtschaftsinformatiker)</b></div>
            </div>




        </div>
    </div>
</div>

<div class="fade_rule"></div>

<div class="mt-l flex-center hide-mobile">
    <a routerLink="/termin" class="apply-btn">
        Jetzt ein Gespräch vereinbaren.
    </a>

</div>

<div class="container bg-gray flex-root round-corners hide-mobile">
    <h2 class="font-size-26">Welche 4 Fertigkeiten du beherrschen wirst </h2>

    <div class="skills">
        <div class="skill-box">
            <img src="./assets/img/skills/1.png">
            <span>Webseiten & Anwendungen bauen</span>
        </div>
        <div class="skill-box">
            <img src="./assets/img/skills/2.png">
            <span>Apps, Spiele & Programme erstellen</span>
        </div>
        <div class="skill-box">
            <img src="./assets/img/skills/3.png">
            <span>IT-Projekte planen</span>
        </div>
        <div class="skill-box">
            <img src="./assets/img/skills/4.png">
            <span>Erfolgreich auf den IT-Job bewerben</span>
        </div>
    </div>

    <div class="mt-l">
        <h2 class="font-size-26"><b>Deine Karriere als Web-Programmierer</b></h2>
        <div class="table mt-l">
            <div class="table-cell table-cell-center">
                <img class="image-soft-round" src="./assets/img/junus_ergin_manuel_thaler.jpg">
            </div>
            <div class="table-cell">
                <h3>Starte eine Karriere mit Perspektive</h3>
                <p class="letter-spacing-1">
                    Wir stellen dir die Weichen für deine Karriere als Fachkraft in der gefragtesten Branche der Welt.
                    Unser Ziel ist, dass du jeden Morgen wieder Lust darauf hast in die Arbeit zu gehen. Denn dort
                    erwarten dich ein Job mit spannenden Aufgaben am Zahn der
                    Zeit, ein attraktives Gehalt und tolle Arbeitsbedingungen.
                </p>
            </div>
        </div>


        <div class="table mt-m mobile-reverse-column">
            <div class="table-cell">
                <h3>Schöpfe dein persönliches Potential aus </h3>
                <p class="letter-spacing-1">
                    Wir legen mit dir zusammen den Grundstein für eine Karriere, in der du dich stetig weiterentwickelst
                    – fachlich & persönlich. Dafür spielt es keine Rolle, ob du bereits Programmiererfahrung mitbringst.
                    In wenigen Wochen bist du wieder auf einem Karriereweg,
                    auf den du stolz sein kannst.
                </p>
            </div>
            <div class="table-cell table-cell-center">
                <img class="image-soft-round" src="./assets/img/phone.jpg">
            </div>
        </div>


        <div class="table mt-m">
            <div class="table-cell table-cell-center">
                <img class="image-soft-round" src="./assets/img/map.jpg">
            </div>
            <div class="table-cell">
                <h3>Werde Teil unseres Netzwerks</h3>
                <p class="letter-spacing-1">
                    Werde Teil der Developer Akademie Community und programmiere zusammen mit anderen Teilnehmern aus
                    Deutschland, Österreich und der Schweiz Webseiten, Spiele und Applikationen. Profitiere von unserem
                    Netzwerk, tausche dich mit deinen Mentoren aus und werde
                    Teil der exklusiven Developer Akademie Alumni.
                </p>
            </div>
        </div>

    </div>
</div>

<div class="container" id="aboutus">
    <div class="table">

        <div class="table-cell width-33 v-align-top">
            <img src="./assets/img/manuel_thaler_junus_ergin.jpg" class="image-soft-round">
        </div>
        <div class="table-cell width-67 v-align-top pl-xl">
            <div class="line-pink hide-mobile"></div>
            <h2>Deine IT-Weiterbildung <br>
                <b class="text-pink"><i>Made in Germany</i></b>
            </h2>
            <p class="text-left">Junus & Manuel leiten als Gründer die Weiterbildung und das Mentoring der Teilnehmer.
                Manuel (33) hat als CFO eines erfolgreichen IT-Unternehmens dutzende IT-Mitarbeiter eingestellt, geführt
                und weiterentwickelt. Er weiß genau was ein Programmierer
                mitbringen muss und wie man in der IT Karriere macht. Er bildet die Teilnehmer in den Themen
                Projekt-Management und Karriere aus und unterstützt mit seiner Erfahrung bei den Bewerbungen. Manuel
                wählt unsere Teilnehmer persönlich aus.</p>
            <p class="text-left">Junus (31) ist das Programmierer-Talent hinter der Developer Akademie. Schon mit 13
                Jahren verdiente er sein Geld mit dem Bauen von Webseiten für Anwaltskanzleien. Mit 26 Jahren bildete er
                als leitender Entwickler eines deutschen IT-Unternehmens
                dutzende neue Programmierer aus. Er verantwortete dort die Entwicklung aller Ärzte-Produkte. Nebenbei
                baute er eine Vielzahl von Apps mit insgesamt deutlich über hunderttausend Downloads.</p>
        </div>
    </div>

    <div class="flex-center mt-l">
        <a routerLink="/termin" class="apply-btn">
            Jetzt ein Gespräch vereinbaren.
        </a>
    </div>
</div>

<div class="video-section container table bg-gray">
    <div class="table-cell v-align-bottom video-description">
        <div class="line-black hide-mobile"></div>
        <h2 class="text-pink font-size-larger"><b>Was wird dich erwarten?</b></h2>
        <p class="text-left font-size-medium">Bei deiner Weiterbildung lernst du nach deiner <b>eigenen
                Geschwindigkeit</b>. Du wirst von deinen <b>persönlichen Mentoren</b> unterstützt, die für dich
            <b>7 Tage</b> die Woche von früh bis spät erreichbar sind. Wir bauen zusammen Projekte und sorgen dafür, dass du schnell ein fundiertes
            IT-Wissen aufbaust, dass von Arbeitgebern überall nachgefragt wird.

        </p>

        <div class="mt-l mb-l d-flex">
            <a routerLink="/termin" routerLinkActive="router-link-active" class="apply-btn">
                <i class="fa fa-arrow-right fa_prepended"></i> Kostenlose Beratung
            </a>
        </div>
    </div>

    <div class="table-cell pr-m pl-m">
        <div class="card flex-center">
            <!-- video-container -->
            <iframe src="//fast.wistia.com/embed/medias/j9wd1cesq6" class="big-screen-only" allowtransparency="true"
                frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen mozallowfullscreen
                webkitallowfullscreen oallowfullscreen msallowfullscreen width="620" height="349"></iframe>
            <iframe src="//fast.wistia.com/embed/medias/j9wd1cesq6" class="medium-screen-only" allowtransparency="true"
                frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen mozallowfullscreen
                webkitallowfullscreen oallowfullscreen msallowfullscreen width="420" height="240"></iframe>
            <iframe src="//fast.wistia.com/embed/medias/j9wd1cesq6" class="small-screen-only" allowtransparency="true"
                frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen mozallowfullscreen
                webkitallowfullscreen oallowfullscreen msallowfullscreen width="420" height="240"></iframe>
            <iframe src="//fast.wistia.com/embed/medias/j9wd1cesq6" class="very-small-screen-only"
                allowtransparency="true" frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen
                mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="320"
                height="180"></iframe>
        </div>
    </div>

</div>

<div class="container hide-mobile">
    <!-- TrustBox widget - Grid -->
    <div class="trustpilot-widget tpw-grid" data-locale="de-DE" data-template-id="539adbd6dec7e10e686debee"
        data-businessunit-id="5e9825574f392a0001029067" data-style-height="500px" data-style-width="100%"
        data-theme="light" data-tags="Weiterbildungspage" data-stars="3,4,5" data-review-languages="de"
        data-text-color="#050505">
        <a href="https://de.trustpilot.com/review/developerakademie.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
    <!-- End TrustBox widget -->
</div>

<!-- Quote Junus -->
<div class="quote container background-quote-image">
    <div class="flex-center">
        <img src="./assets/img/quote.png">
    </div>
    <p class="font-size-large text-center letter-spacing-1">"Der beste Zeitpunkt systematisch Programmieren zu lernen
        war gestern. Der Zweitbeste ist heute."
    </p>
    <div>
        <span class="text-pink">JUNUS ERGIN</span>
    </div>
</div>


<div class="container bg-dark cta-section">
    <div class="table">
        <div class="table-cell">
            <p class="font-size-large text-left">Du möchtest als <b>Programmierer</b> arbeiten oder durch
                Programmier-Kenntnisse deine Karriere-Optionen deutlich verbessern?</p>
        </div>
        <div class="table-cell">
            <div class="flex-center">
                <a routerLink="/termin" class="apply-btn">
                    Jetzt ein Gespräch vereinbaren.</a>
            </div>
        </div>
    </div>
</div>

<div class="container hide-mobile">
    <div class="table">
        <div class="table-cell v-align-top">
            <div class="line-pink"></div>
            <h2 class="font-size-xl">DEIN <span class="text-pink"><b>COACHING-TEAM</b></span></h2>

            <p class="text-left font-size-medium">Als Team aus erfahrenen IT-Managern, Programmierern und Designern,
                können wir dir gezielt alles beibringen, was du benötigst, um dich systematisch zum Programmierer
                weiterzuentwickeln.
            </p>
            <p class="text-left font-size-medium pr-xl">Deine Weiterbildung ist nicht dann erfolgreich, wenn du dir
                lediglich neues Wissen angeeignet hast. Es kommt darauf an, dass du danach den Schritt schaffst eine
                neue Rolle auszufüllen und eine neue Karriere zu starten. Als Team mit allen
                notwendigen Kompetenzen bringen wir dir von Anfang an bei, wie ein Programmierer zu arbeiten und deinen
                Platz in der IT-Welt zu finden. </p>
            <p class="text-left font-size-medium">Dafür stehen wir als Developer Akademie.</p>

            <a href="https://m.certipedia.com/certificates/01%20600%202000730?locale=de" target="blank">
                <img src="./assets/img/TR-Testmark_9000012503_DE_CMYK_without-QR-Code.png" class="certificate">
            </a>

        </div>



        <div class="table-cell team-members">
            <div class="table">
                <div class="table-cell v-align-top">
                    <img class="image-soft-round" src="./assets/img/team/1.jpg">
                    <div class="text-center"><b>Manuel Thaler</b></div>
                    <div class="text-center pr-m pl-m"><span>Co-Founder & Karriereberater</span></div>
                </div>
                <div class="table-cell v-align-top mobile-mt-l">
                    <img class="image-soft-round" src="./assets/img/team/2.jpg">
                    <div class="text-center"><b>Junus Ergin</b></div>
                    <div class="text-center pr-m pl-m"><span>Co-Founder & Tech-Mentor</span></div>
                </div>
            </div>
            <div class="table mt-l">
                <div class="table-cell v-align-top">
                    <img class="image-soft-round" src="./assets/img/team/3.jpg">
                    <div class="text-center"><b>Jessica</b></div>
                    <div class="text-center pr-m pl-m"><span>Talent Scout</span></div>
                </div>
                <div class="table-cell v-align-top mobile-mt-l">
                    <img class="image-soft-round" src="./assets/img/team/4.jpg">
                    <div class="text-center"><b>Mihai</b></div>
                    <div class="text-center pr-m pl-m"><span>Software-Entwickler & Tech-Mentor</span></div>
                </div>
            </div>

        </div>
    </div>

</div>

<a href="https://m.certipedia.com/certificates/01%20600%202000730?locale=de" target="blank">
    <img src="./assets/img/TR-Testmark_9000012503_DE_CMYK_without-QR-Code.png" class="certificate hide-desktop mt-l">
</a>

<div class="container location">
    <div class="line-pink"></div>
    <h2 class="font-size-xl">UNSER <span class="text-pink"><b>STANDORT</b></span></h2>
    <p>Unser Büro befindet sich im Werksviertel in München, in der Rosenheimer Straße 139.</p>
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1331.6473304887782!2d11.601511565370634!3d48.12384066471629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ddf8378e729b3%3A0x597089178dfbfc81!2sRosenheimer%20Str.%20139%2C%2081671%20M%C3%BCnchen!5e0!3m2!1sde!2sde!4v1656943547679!5m2!1sde!2sde"
        frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>