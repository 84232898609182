import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class ThankYouPageEventTrackingService {

  constructor() { }
  private nameSubject = new BehaviorSubject<string>('');
  private experienceSubject = new BehaviorSubject<string>('');

  setName(name: string) {
    this.nameSubject.next(name);
  }

  setExperience(experience: string) {
    this.experienceSubject.next(experience);
  }

  getName() {
    return this.nameSubject.asObservable();
  }

  getExperience() {
    return this.experienceSubject.asObservable();
  }

  hasNoExperience() {
    return this.getExperience().pipe(
        map(experience => experience == "Ich habe noch nie eine Zeile Programm-Code geschrieben.")
    )
  }

  public trackEvent() {
    gtag('event', 'conversion', { 'send_to': 'AW-939903939/D6csCPnP6IsCEMOXl8AD' });
  }
}
