import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { DevSecOpsApplyComponent } from './dev-sec-ops-apply/dev-sec-ops-apply.component';
import { FullStackApplyComponent } from './full-stack-apply/full-stack-apply.component';


const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 't',
    component: LandingComponent,
  },
  {
    path: 'ya',
    component: LandingComponent,
  },
  {
    path: 'y',
    component: LandingComponent,
  }, {
    path: 'g',
    component: LandingComponent,
  },
  {
    path: 'info',
    component: LandingComponent,
  },
  {
    path: 'f',
    component: LandingComponent,
  },
  {
    path: 'termin',
    component: FullStackApplyComponent,
  },
  {
    path: 'devsecops',
    component: DevSecOpsApplyComponent,
    title: "Werde DevSecOps-Experte in 3-9 Monaten | Developer Akademie"
  },
  {
    path: 'bewerbung-eingegangen',
    component: ThankYouPageComponent,
  },
  {
    path: '**',
    component: LandingComponent,
    title: "Weiterbildung zum Programmierer - In Teilzeit oder Vollzeit in 3 - 9 Monaten zum gefragten Softwareentwickler | Developer Akademie"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
