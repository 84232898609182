import { Injectable } from '@angular/core';

export declare type InitialSource = 'facebook' | 'google' | 'youtube' | 'youtube-ads' | 'tiktok' | 'unbekannt';

export enum UTMParamEnum {
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_ID = 'utm_id',
    UTM_TERM = 'utm_term',
    UTM_CONTENT = 'utm_content'
}

export declare type UTMParamName =
    UTMParamEnum.UTM_CAMPAIGN |
    UTMParamEnum.UTM_CONTENT |
    UTMParamEnum.UTM_ID |
    UTMParamEnum.UTM_MEDIUM |
    UTMParamEnum.UTM_SOURCE |
    UTMParamEnum.UTM_TERM;

export interface UTMParameter {
    name: UTMParamName;
    value: string;
}

@Injectable({
    providedIn: 'root'
})
export class TrackingserviceService {
    initialSource: InitialSource = 'unbekannt';
    readonly UTM_NAMES: UTMParamName[] = Object.values(UTMParamEnum) as UTMParamName[];

    /**
     * Helper method to safely access localStorage
     */
    private getLocalStorageItem(key: string): string {
        try {
            return localStorage.getItem(key) || "";
        } catch (err) {
            console.warn(`LocalStorage access blocked or failed for key: ${key}`, err);
            return "";
        }
    }

    /**
     * Helper method to safely set localStorage item
     */
    private setLocalStorageItem(key: string, value: string): void {
        try {
            localStorage.setItem(key, value);
        } catch (err) {
            console.warn(`Unable to store ${key} in localStorage`, err);
        }
    }

    /**
     * Helper method to safely remove localStorage item
     */
    private removeLocalStorageItem(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (err) {
            console.warn(`Unable to remove ${key} in localStorage`, err);
        }
    }

    getInitialSource(): InitialSource {
        const initialSourceFromStorage = this.getLocalStorageItem('initialSource');
        if (initialSourceFromStorage) {
            return initialSourceFromStorage as InitialSource;
        }

        const url = window.location.href;
        let initialSource: InitialSource = 'facebook';

        if (url.endsWith('/g') || url.endsWith('/g/') || url.endsWith('/info') || url.endsWith('/info/')) {
            initialSource = 'google';
        } else if (url.endsWith('/y') || url.endsWith('/y/')) {
            initialSource = 'youtube';
        } else if (url.endsWith('/ya') || url.endsWith('/ya/')) {
            initialSource = 'youtube-ads';
        } else if (url.endsWith('/t') || url.endsWith('/t/')) {
            initialSource = 'tiktok';
        }

        return initialSource;
    }

    saveInitialSource(): void {
        const initialSource = this.getInitialSource();
        this.setLocalStorageItem('initialSource', initialSource);
    }

    removeInitialSource(): void {
        this.removeLocalStorageItem('initialSource');
    }

    /**
     * Stores the UTM Parameters in localStorage if they are found in the URL.
     */
    storeUTMParameters(): void {
        this.UTM_NAMES.forEach(utmName => {
            const value = this.searchUTMParameter(utmName);
            if (value && !this.getLocalStorageItem(utmName)) {
                this.setLocalStorageItem(utmName, value);
            }
        });
    }

    /**
     * Retrieves a UTM parameter value from localStorage, falling back to URL search.
     * @param utmName - Name of the UTM parameter.
     * @returns The value of the UTM parameter, or null if not found.
     */
    getUTMParameterValue(utmName: UTMParamName): string {
        const valueFromStorage = this.getLocalStorageItem(utmName);
        if (valueFromStorage) {
            return valueFromStorage;
        }
        return this.searchUTMParameter(utmName);
    }

    /**
     * Constructs an object for UTM parameters as needed by consumers.
     * @param utmName - Name of the UTM parameter.
     * @returns Object containing the key and its value.
     */
    getUTMParameter(utmName: UTMParamName): { key: string; value: string } {
        return {
            key: utmName as string,
            value: this.getUTMParameterValue(utmName)
        };
    }

    /**
     * Returns an array of all UTM parameters currently stored or found in the URL.
     */
    get utmParameters(): UTMParameter[] {
        return this.UTM_NAMES.map(utmName => ({
            name: utmName,
            value: this.getUTMParameterValue(utmName) || ''
        }));
    }

    /**
     * Searches the window location for a given UTM parameter.
     * @param utmParameterName - Name of the UTM parameter.
     * @returns The value of the UTM parameter in the URL
     */
    searchUTMParameter(utmParameterName: UTMParamName): string {
        return new URLSearchParams(window.location.search).get(utmParameterName) || "";
    }

    /**
     * Removes all UTM parameters from localStorage.
     */
    removeUTMParameters(): void {
        this.UTM_NAMES.forEach(utmName => {
           this.removeLocalStorageItem(utmName);
        });
    }
}
