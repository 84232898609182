import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


const sentryConf = {
  dsn: "https://4d0980a2cd064a90bd778ad3afe92be0@o1334976.ingest.sentry.io/6604737",
  tunnel: environment.sentryPartialConf.tunnel,
  replaysSessionSampleRate: environment.sentryPartialConf.replaysSessionSampleRate,
  replaysOnErrorSampleRate: environment.sentryPartialConf.replaysOnErrorSampleRate,
  debug: environment.sentryPartialConf.debug,
  release: `${environment.appName}@${environment.appVersion}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: environment.sentryPartialConf.tracesSampleRate,
  environment: environment.envName,
  tracePropagationTargets: environment.sentryPartialConf.tracePropagationTargets
} as Sentry.BrowserOptions;

Sentry.init(sentryConf);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
