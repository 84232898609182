import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { LandingComponent } from './landing/landing.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DevSecOpsApplyComponent } from './dev-sec-ops-apply/dev-sec-ops-apply.component';

import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { CustomFormLibModule } from 'custom-form-lib';
import { FullStackApplyComponent } from './full-stack-apply/full-stack-apply.component';
import { FooterComponent } from './footer/footer.component';



@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LandingComponent,
    ThankYouPageComponent,
    DevSecOpsApplyComponent,
    FullStackApplyComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomFormLibModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
