import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThankYouPageEventTrackingService } from '../services/thank-you-page-event-tracking.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    @Input() darkmode: boolean = false;
    year = new Date().getFullYear();
    hasNoExperience = false;

    constructor(private router: Router,
        private thankYouPageEventTrackingService: ThankYouPageEventTrackingService
    ) { }
    ngOnInit(): void {
        this.thankYouPageEventTrackingService.hasNoExperience().subscribe(experience => {
            this.hasNoExperience = experience;
        });
    }

    isTanksPage(): boolean {
        return this.router.url === '/bewerbung-eingegangen';
    }

}
